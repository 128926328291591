import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageProps } from 'gatsby';
import Image from 'gatsby-image';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './media.css';
import { ContentfulImage } from '../types/contentful-types';
import { SiteMetadata } from '../types/site-metadata';
import { useEffect } from 'react';
import AdSense from 'react-adsense';

type MediaType = 'News' | 'Youtube' | 'Tech & Business' | 'Culture & Consumerism';
type Media = {
  node: {
    type: MediaType,
    title: string,
    link: string,
    backgroundImage: ContentfulImage
  }
};

type DataProps = {
  site: SiteMetadata;
  allContentfulMedia: {
    edges: [Media]
  };
};

const MediaPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const renderMediaType = (media: Media[], type: MediaType) => {
    const mediaToRender = media.filter(({ node }) => node.type === type);
    const renderedMedia = mediaToRender.map(({ node }) => {
      return (
        <Col className="p-2">
          {type != 'Youtube' &&
            <Link to={node.link} target={'_blank'}>
              <Card className="text-white text-center border-0">
                <Image
                  fluid={node.backgroundImage.fluid}
                  alt={node.backgroundImage.title}
                  key={node.backgroundImage.title}
                />
                <Card.ImgOverlay className="dim-background">
                  <Card.Title className="font-weight-bold text-uppercase align-center">{node.title}</Card.Title>
                </Card.ImgOverlay>
              </Card>
            </Link>
          }
          {type === 'Youtube' &&
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src={node.link} frameBorder="0" />
            </div>
          }
        </Col >
      );
    });
    return (
      <>
        <Row>
          <Col className="h2 font-weight-bold text-uppercase">{type}</Col>
        </Row>
        <Row xs={2} md={3} lg={4}>
          {renderedMedia}
        </Row>
      </>
    )
  };

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="People" />
      <Container fluid={true}>
        {renderMediaType(data.allContentfulMedia.edges, 'News')}
        {renderMediaType(data.allContentfulMedia.edges, 'Youtube')}
        <Row>
          <Col>
            <AdSense.Google
              client='ca-pub-6101154553358582'
              slot='3402285668'
              style={{ display: 'block' }}
              format='auto'
              responsive='true'
            />
          </Col>
        </Row>
        {renderMediaType(data.allContentfulMedia.edges, 'Tech & Business')}
        {renderMediaType(data.allContentfulMedia.edges, 'Culture & Consumerism')}
      </Container>
    </Layout>
  );
};

export default MediaPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulMedia {
      edges {
        node {
          type
          title
          link
          backgroundImage {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
